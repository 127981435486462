import React from "react";
import { useState, useEffect } from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";
import Cookies from 'universal-cookie';
import { fetchFromServer } from './Helper';
import Home from '../pages/Home';
import Loading from '../components/Loading'
import LoadingPage from '../pages/Loading'

// import { jwtDecode } from "jwt-decode";

// Example function to check authentication
// You can replace this with your actual authentication check logic
const isAuthenticated = async () => {
    const cookies = new Cookies();
    const token = cookies.get("authorization"); // Fetch the token from storage

    if (token === undefined) {
        console.log('🔴 Missing cookie, redirecting to login.');
        return false; // No token, not authenticated
    }

    try {
        // const res = await fetch(`https://${process.env.REACT_APP_NODE_IP}/verifyCookie`, {
        //     method: 'GET',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Authorization': token
        //     }
        // });
        const res = await fetchFromServer('verifyCookie', 'GET')
        console.log(res);
            if (res.status === 200) {
                console.log('🟢 User logged in, redirecting to target.');
                return true;
            } else {
                console.log('🔴 Cookie found invalid, redirecting to login.');
                return false;
            }
        // });
    } catch (error) {
        console.log(`🔴 Error verifying cookie, redirecting to login. ${error}`);
        return false; // If decoding fails, the token is invalid
    }
};


const ProtectedRoute = () => {
    const [auth, setAuth] = useState(null);
    const location = useLocation();

    useEffect(() => {
        const verifyAuth = async () => {
            const authenticated = await isAuthenticated();
            setAuth(authenticated);
        };

        verifyAuth();
    }, []);

    if (auth === null) {
        console.log('🔵 Awaiting user authentication.');
        // While verifying the authentication, you could show a loading indicator
        return <div><Loading/><LoadingPage/></div>;
    }

    if (!auth) {
        console.log('🔴 User not authenticated, redirecting to login.');
        return <Navigate to="/auth" state={{ from: location }} replace />;
    }

    console.log('🟢 User authenticated, rendering target page.');
    return (
        <div>
            <Outlet />
        </div>
    );
};

export default ProtectedRoute;
