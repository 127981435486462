// Pricing.js
import React from 'react';

import pricingStyles from './Pricing.module.css';

import Banner from '../components/Banner';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';

function Pricing() {
  return (
    <React.StrictMode>
      <Banner />
      <div className={pricingStyles['main-wrapper']}>
        <div className={pricingStyles['main-prompt-wrapper']}>
          <h1 className={pricingStyles['main-header']}>Professional Cover Letters, Simplified.</h1>
          <h2>Access <grad>CovrAI</grad> with flexible plans tailored to your job hunt needs. (Beta Testing, Contact Dev Team for Access)</h2>
        </div>

        {/* Free box*/}

        <div className={pricingStyles['pricing-container']}>
          <Link to="/notfound">
            <div className={`${pricingStyles['pricing-box']} ${pricingStyles['pricing-box-trial']}`}>
              <div>
                <h1 className={pricingStyles['price-heading']}>Trial</h1>
                <h2 className={pricingStyles['price']}>The Trailblazer</h2>
                <h3>Free Forever</h3>
                <ul className={pricingStyles['perk-list']}>
                  <li><span>Access to <grad>CovrAI</grad></span></li>
                  <li><span><blue>3</blue> Cover Letters Per Week</span></li>
                  <li><span><grad>One Click</grad> Extension Trial</span></li>
                  <li><span><blue>Basic</blue> Templates</span></li>
                  <li><span>Context-Aware Writing</span></li>
                </ul>
              </div>
              <div><button><h2>Pre-Register</h2></button></div>
            </div>
          </Link>

          {/* Student Box */}
          <Link to="/notfound">
            <div className={`${pricingStyles['pricing-box']} ${pricingStyles['pricing-box-basic']}`}>
              <div>
                <h1 className={pricingStyles['price-heading']}>Student</h1>
                <h2 className={pricingStyles['price']}>Unlimited Grind Mode</h2>
                <h3>$6/month</h3>
                <ul className={pricingStyles['perk-list']}>
                  <li><span>Access to <grad>CovrAI</grad></span></li>
                  <li><span><blue>60</blue> Cover Letters Per Week</span></li>
                  <li><span><grad>One Click</grad> Extension</span></li>
                  <li><span><blue>Basic</blue> Templates</span></li>
                  <li><span>Context-Aware Writing</span></li>
                </ul>
                <h4>Upgrades from Trial</h4>
                <ul className={pricingStyles['perk-list']}>
                  <hr></hr>
                  <li><span><blue>Advanced</blue> Templates</span></li>
                  <li><span>Tone Customization</span></li>
                  <li><span>Skill Selection</span></li>
                  <li><span>Personalized Experiences</span></li>
                </ul>
              </div>
              <div><button><h2>Pre-Register</h2></button></div>
            </div>
          </Link>
          
          {/* Basic Box */}
          <Link to="/notfound">
            <div className={`${pricingStyles['pricing-box']} ${pricingStyles['pricing-box-basic']}`}>
              <div>
                <h1 className={pricingStyles['price-heading']}>Basic</h1>
                <h2 className={pricingStyles['price']}>The Overachiever</h2>
                <h3>$10/month</h3>
                <ul className={pricingStyles['perk-list']}>
                  <li><span>Access to <grad>CovrAI</grad></span></li>
                  <li><span><blue>60</blue> Cover Letters Per Week</span></li>
                  <li><span><grad>One Click</grad> Extension</span></li>
                  <li><span><blue>Basic</blue> Templates</span></li>
                  <li><span>Context-Aware Writing</span></li>
                </ul>
                <h4>Upgrades from Trial</h4>
                <ul className={pricingStyles['perk-list']}>
                  <hr></hr>
                  <li><span><blue>Advanced</blue> Templates</span></li>
                  <li><span>Tone Customization</span></li>
                  <li><span>Skill Selection</span></li>
                  <li><span>Personalized Experiences</span></li>
                </ul>
              </div>
              <div><button><h2>Pre-Register</h2></button></div>
            </div>
          </Link>
          {/* Premium Box */}
          <Link to="/notfound">
            <div className={`${pricingStyles['pricing-box']} ${pricingStyles['pricing-box-premium']}`}>
              <div>
                <h1 className={pricingStyles['price-heading']}>Premium</h1>
                <h2 className={pricingStyles['price']}>Master of Your Path</h2>
                <h3>$20/month</h3>
                <ul className={pricingStyles['perk-list']}>
                  <li><span>Access to <grad>CovrAI</grad></span></li>
                  <li><span><blue>360</blue> Cover Letters Per Week</span></li>
                  <li><span><grad>One Click</grad> Extension</span></li>
                  <li><span><blue>Basic</blue> Templates</span></li>
                  <li><span><blue>Advanced</blue> Templates</span></li>
                  <li><span>Context-Aware Writing</span></li>
                  <li><span>Tone Customization</span></li>
                  <li><span>Skill Selection</span></li>
                  <li><span>Personalized Experiences</span></li>
                </ul>
                <h4>Upgrades from Basic</h4>
                <ul className={pricingStyles['perk-list']}>
                  <hr></hr>
                  <li><span><blue>Custom</blue> Templates</span></li>
                </ul>
              </div>
              <div><button><h2>Pre-Register</h2></button></div>
            </div>
          </Link>
        </div>
      </div>
      <Footer />
    </React.StrictMode >
  );
}

export default Pricing;