// components/Header.js
import React from 'react';
import styles from './Footer.module.css';
import { Link } from 'react-router-dom';

function Banner() {
  return (
    <div className={styles['banner-wrapper']}>
      <div className={styles['content-wrapper']}>
        <div className={styles['option-wrapper']}>
          <div className={styles['column-wrapper']}>
            <h2>About Us 😊</h2>
            <h3><Link to ="/About">Our Company</Link></h3>
            <h3>The Team</h3>
            <h3>Careers</h3>
          </div>
          <div className={styles['column-wrapper']}>
            <h2>Socials 🎉</h2>
            <h3><Link to ="https://github.com/Project-Unmei">LinkedIn</Link></h3>
            <h3><Link to ="/NotFound">Github</Link></h3>
            <h3><Link to ="/NotFound">Instagram</Link></h3>
          </div>
          <div className={styles['column-wrapper']}>
            <h2>Policies 👩‍⚖️</h2>
            <h3><Link to ="/tos">Terms of Service</Link></h3>
            <h3><Link to ="/privacy">Privacy Statement</Link></h3>
          </div>
        </div>
        <div className={styles['last-option-wrapper']}>
          <div className={styles['column-wrapper']}>
            <h2>info@covr-ai.com</h2>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
