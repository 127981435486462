import React from 'react';
import styles from './Loading.module.css'; // Add CSS for styling

const LoadingOverlay = () => {
  return (
    <div className={styles["loading-overlay"]}>
      <div className={styles["spinner"]}></div>
    </div>
  );
};

export default LoadingOverlay;
