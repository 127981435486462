// Home.js
import React from 'react';


import Banner from '../components/Banner';
import Footer from '../components/Footer';
import styles from './NotFound.module.css';

function Home() {
  return (
    <React.StrictMode>
      <Banner />
      <div className={styles["content-wrapper"]}>
        <h5>Where are my stuff? (404)</h5>
        <img src="https://media1.tenor.com/m/-mPveTlnaOkAAAAd/furina-genshin.gif"/>
      </div>
      <Footer />
    </React.StrictMode>
  );
}

export default Home;
