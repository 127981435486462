import React, { useState } from 'react';
import { Switch, FormControlLabel, FormGroup, FormControl, Slider, Tooltip, InputLabel, Select, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import styles from './GenerateForm.module.css';

import Dropzone from './Dropzone';
import Loading from './Loading';
import { softLoginCheck } from '../helpers/LoginCheck';

import { pdfjs } from 'react-pdf';
import { Form } from 'react-router-dom';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  `pdfjs-dist/build/pdf.worker.min.mjs`,
  import.meta.url,
).toString();



const StyledDropdown = styled(Select)(({ theme }) => ({
  '& .MuiMenu-list': {
    backgroundColor: 'lightblue',  // Custom background for the ul
    padding: 0,
  },
  '& .MuiMenuItem-root': {
    color: 'darkblue',             // Custom color for MenuItems
    '&:hover': {
      backgroundColor: 'lightgray', // Custom hover color
    },
  },
}));

const PrettoSlider = styled(Slider)({
  color: getComputedStyle(document.body).getPropertyValue('--palette-blue-2'),
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  }
});

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: getComputedStyle(document.body).getPropertyValue('--shade-1'),
      '& + .MuiSwitch-track': {
        backgroundColor: getComputedStyle(document.body).getPropertyValue('--palette-blue-2'),
        opacity: 1,
        border: 0,
        ...theme.applyStyles('dark', {
          backgroundColor: getComputedStyle(document.body).getPropertyValue('--palette-blue-2'),
        }),
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100],
      ...theme.applyStyles('dark', {
        color: theme.palette.grey[600],
      }),
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
      ...theme.applyStyles('dark', {
        opacity: 0.3,
      }),
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    backgroundColor: getComputedStyle(document.body).getPropertyValue('--shade-1'),
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: getComputedStyle(document.body).getPropertyValue('--shade-2'),
    opacity: 1,
  },
}));



const GenerateForm = ({ formData, changeFormData, userInfo}) => {
  const [loading, setLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(softLoginCheck());

  const Bubble = ({ name, isActive, onClick }) => {
    return (
      <div onClick={onClick} className={`${styles['bubble']} ${isActive ? styles['active'] : ''} ${!loggedIn ? styles['blurred'] : ""}`}>
        <h3>{name}</h3>
      </div>
    );
  };

  const avilable_tones = [
    "Professional Formal",
    "Polished and Confident",
    "Friendly and Engaging",
    "Conversational Casual",
    "Casual Playful"
];

  // Function to handle bubble click
  const handleBubbleClick = (itemName) => {
    // Check to make sure there isnt more than 3 active items
    if (formData.desiredSkills.length >= 3 && !formData.desiredSkills.includes(itemName)) {
      return;
    }

    if (formData.desiredSkills.includes(itemName)) {
      // If already active, remove from active list
      formData.desiredSkills = formData.desiredSkills.filter((item) => item !== itemName);
    } else {
      // If not active, add to active list
      formData.desiredSkills.push(itemName);
    }

    handleChange({ target: { name: 'desiredSkills', value: formData.desiredSkills } });
  };
  
  const handleChange = (e) => {
    // Modify the object based on input change
    const { name, type, checked, value } = e.target;
    const inputValue = type === 'checkbox' ? checked : value;
    const updatedObject = { ...formData, [name]: inputValue };
    
    // Trigger the update function passed from the parent
    changeFormData(updatedObject);
    console.log('FORM DATA:', updatedObject);
  };

  return (
    <React.StrictMode>
      {loading && <Loading />}
      <form>
        <div className={styles['customize-wrapper']}>
          <Tooltip title={!loggedIn ? "Basic+ Users Only" : ""} placement="left">
            <FormGroup>
              <FormControlLabel className={styles['form-group']} control={<IOSSwitch checked={formData.autoTone} name="autoTone" onChange={handleChange} disabled={!loggedIn} />} label={<h2><grad>{formData.autoTone ? "Auto" : "Custom"}</grad> Writing Tone</h2>} />
            </FormGroup>
          </Tooltip>
          <PrettoSlider className={styles['slider-group']}
            aria-label="tone-control"
            shiftStep={10}
            step={1}
            marks
            min={0}
            max={4}
            valueLabelDisplay='off'
            valueLabelFormat={(value) => avilable_tones[value]}
            name="desiredTone"
            onChange={handleChange}
            disabled={!(loggedIn && !formData.autoTone)} />
          <h4>{!formData.autoTone ? avilable_tones[formData.desiredTone] : <grad>Job Adaptive</grad>}</h4>
        </div>


        <div className={styles['customize-wrapper']}>
          <Tooltip title={!loggedIn ? "Basic+ Users Only" : ""} placement="left">
            <FormGroup>
              <FormControlLabel className={styles['form-group']} control={<IOSSwitch checked={formData.autoSkills} name="autoSkills" onChange={handleChange} disabled={!loggedIn} />} label={<h2><grad>{formData.autoSkills ? "Auto" : "Custom"}</grad> Skill Highlight</h2>} />
            </FormGroup>
          </Tooltip>
          <div>
            <Tooltip title={formData.desiredSkills.length >= 3 ? "Max 3 Skills" : ""} placement="bottom">
              <div className={`${styles['skill-box-wrapper']} ${formData.autoSkills ? styles['disabled'] : ""}`}>
                {userInfo.skills.map((item) => (
                  <Bubble
                    key={item}
                    name={item}
                    isActive={formData.desiredSkills.includes(item)}
                    onClick={() => handleBubbleClick(item)}
                  />
                ))}
              </div>
            </Tooltip>
          </div>
          <h4>{!formData.autoSkills ? (formData.desiredSkills.length > 0 ? formData.desiredSkills.join(', ') : <blue>Select Skills Above</blue>) : <grad>Job Adaptive</grad>}</h4>
        </div>


        <div className={styles['customize-wrapper']}>
          <Tooltip title={!loggedIn ? "Basic+ Users Only" : ""} placement="left">
            <FormGroup>
              <FormControlLabel className={styles['form-group']} control={<IOSSwitch checked={formData.autoExperience} name='autoExperience' onChange={handleChange} disabled={!loggedIn} />} label={<h2><grad>{formData.autoExperience ? "Auto" : "Custom"}</grad> Experience Highlight</h2>} />
            </FormGroup>
          </Tooltip>
          <div className={styles['dropdown-wrapper']}>
            <FormControl fullWidth className={!(loggedIn && !formData.autoExperience) ? styles['disabled'] : ""}>
              <Select
                className={styles['dropdown-group']}
                labelId="exp-dropdown-label"
                label="Experiences"
                name='desiredExperience'
                value={formData.desiredExperience}
                disabled={!(loggedIn && !formData.autoExperience)}
                sx={{
                  '.MuiOutlinedInput-notchedOutline': {
                    border: 'none', // Disable the border here
                  },
                }}
                onChange={handleChange}
              >
                {userInfo.experiences.map((item) => (
                  <MenuItem className={styles['dropdown-item']}
                    key={`${item.POSITION} ${item.ORGANIZATION}`}
                    name={`${item.POSITION} ${item.ORGANIZATION}`}
                    value={`${item.POSITION} ${item.ORGANIZATION}`}
                  >{item.POSITION} @ {item.ORGANIZATION}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <h4>{!formData.autoExperience ? (formData.desiredExperience ? userInfo.experiences.find(exp => exp.POSITION === formData.desiredExperience)?.ORGANIZATION : <blue>Select Experience Above</blue>) : <grad>Job Adaptive</grad>}</h4>
        </div>
      </form>

    </React.StrictMode>
  );
}

export default GenerateForm;
