import React, { useState } from 'react';
import styles from './UserInfoForm.module.css';
import { Button } from '@mui/material';

import Dropzone from './Dropzone';
import Loading from './Loading';

import { softLoginCheck } from '../helpers/LoginCheck';

import { pdfjs } from 'react-pdf';
import { fetchFromServer } from '../helpers/Helper';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  `pdfjs-dist/build/pdf.worker.min.mjs`,
  import.meta.url,
).toString();


const UserInfoForm = ({ changeForm, formData, changeFormData, fieldStatus, changeFieldStatus }) => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [loggedIn, setLoggedIn] = useState(softLoginCheck());
  // const [formData, setFormData] = useState({
  //   name: '',
  //   email: '',
  //   title: '',
  //   phone: '',
  //   education: '',
  //   study: '',
  //   createAccount: true,
  //   resume: '',
  //   password: '',
  // });

  const handleChange = (e) => {
    // Modify the object based on input change
    const { name, type, checked, value } = e.target;
    const inputValue = type === 'checkbox' ? checked : value;
    const updatedObject = { ...formData, [name]: inputValue };

    // Trigger the update function passed from the parent
    changeFormData(updatedObject);
  };

  const resetStatus = (e) => {
    const { name } = e.target;
    const updatedStatus = { ...fieldStatus, [`user_${name}`]: false };
    changeFieldStatus(updatedStatus);
  }

  const parsePdfToText = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = async function () {
        const typedArray = new Uint8Array(reader.result);
        const loadingTask = pdfjs.getDocument(typedArray);

        try {
          const pdf = await loadingTask.promise;
          let fullText = '';

          for (let i = 1; i <= pdf.numPages; i++) {
            const page = await pdf.getPage(i);
            const textContent = await page.getTextContent();

            // Extract text from each item in the content array
            const pageText = textContent.items.map((item) => item.str).join(' ');

            fullText += pageText; // Append page text to the full text
          }

          // Remove repeating non-alphanumeric characters and trim the text
          fullText = fullText.replace(/[^0-9a-zA-Z]+/g, ' ').trim();
          resolve(fullText);
        } catch (error) {
          console.error('Error parsing PDF:', error);
        }
      };
      reader.readAsArrayBuffer(file);
    });
  };

  // Function to send parsed text to the API
  const sendParsedText = async (text) => {
    // Set loading state to true
    setLoading(true);

    try {
      const jsonPayload = JSON.stringify({
        DATA: {
          TARGET: text,
        }
      });

      // const response = await fetch(`https://${process.env.REACT_APP_NODE_IP}/api/extract/resume_info`, {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'authorization': localStorage.getItem('token')
      //   },
      //   body: jsonPayload, // Send the extracted text as JSON
      // });

      // if (!response.ok) {
      //   throw new Error('Failed to send parsed text to the server');
      // }

      // const data = await response.json();

      const data = await fetchFromServer(
        'api/extract/resume_info',
        'POST',
        jsonPayload,
        undefined, undefined,
        'Failed to send parsed text to the server');

      console.log("user is", data);

      const user_data = data.DATA.USER; // No need to await here, data is already available

      console.log('Parsed text sent successfully');
      setLoading(false);
      return user_data; // Resolve the promise with user data
    } catch (error) {
      console.error('Error sending parsed text:', error);
      setLoading(false);
      throw error; // Reject the promise by throwing the error
    }
  };


  const handleFileDrop = async (file) => {
    setFile(file);
    try {
      const text = await parsePdfToText(file);
      console.log('Parsed Text:', text);
      // Change the extracted data keys to lowercase


      // Aggregate text and extracted_data and call handleChange once
      const tempFormData = {
        ...formData,
        resume: text
      }

      console.log('TEMP FORM DATA:', tempFormData);

      if (tempFormData['name'] === "" || tempFormData['email'] === "" || tempFormData['title'] === "" || tempFormData['phone'] === "" || tempFormData['education'] === "" || tempFormData['study'] === "" || tempFormData['location'] === "" || !tempFormData['skills'].length || !tempFormData['experiences'].length) {
        try {
          const extracted_data = await sendParsedText(text);
          tempFormData['name'] = tempFormData['name'] === "" ? extracted_data.NAME : tempFormData['name'];
          tempFormData['email'] = tempFormData['email'] === "" ? extracted_data.EMAIL : tempFormData['email'];
          tempFormData['title'] = tempFormData['title'] === "" ? extracted_data.TITLE : tempFormData['title'];
          tempFormData['phone'] = tempFormData['phone'] === "" ? extracted_data.PHONE : tempFormData['phone'];
          tempFormData['education'] = tempFormData['education'] === "" ? extracted_data.EDUCATION : tempFormData['education'];
          tempFormData['study'] = tempFormData['study'] === "" ? extracted_data.STUDY : tempFormData['study'];
          tempFormData['location'] = tempFormData['location'] === "" ? extracted_data.LOCATION : tempFormData['location'];
          tempFormData['skills'] = !tempFormData['skills'].length ? Object.values(extracted_data.SKILLS).map(item => item.NAME) : tempFormData['skills'];
          tempFormData['experiences'] = !tempFormData['experiences'].length ? extracted_data.EXPERIENCES : tempFormData['experiences'];
        } catch (error) {
          console.error('Error sending parsed text:', error);
        }
      }

      await changeFormData(tempFormData);
    } catch (error) {
      console.error('Error parsing PDF:', error);
    };
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    changeFormData({
      ...formData,
      [name]: value
    });
    // Check if the password is at least 8 characters long
    if (value.length < 8) {
      setError('Password must be at least 8 characters long.');
    } else {
      setError('');
    }
    handleChange(e);
  };

  return (
    <React.StrictMode>
      {loading && <Loading />}
      <div className={styles['content-wrapper']}>
        <form>
          <div className={styles['input-wrapper']}>
            <label>
              <input
                className={fieldStatus['user_name'] ? styles['unfilled'] : ""}
                type="text"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={(e) => { handleChange(e); resetStatus(e); }}
              />
              Name*
            </label>

            <label>
              <input
                className={fieldStatus['user_email'] ? styles['unfilled'] : ""}
                type="text"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={(e) => { handleChange(e); resetStatus(e); }}
              />
              Email*
            </label>
          </div>

          <div className={styles['input-wrapper']}>
            <label>
              <input
                type="text"
                name="title"
                placeholder="Title"
                value={formData.title}
                onChange={handleChange}
              />
              Title (Mr./Ms. CFA/MBA)
            </label>

            <label>
              <input
                type="text"
                name="phone"
                placeholder="Phone Number"
                value={formData.phone}
                onChange={handleChange}
              />
              Phone Number
            </label>
          </div>

          <div className={styles['input-wrapper']}>
            <label>
              <input
                type="text"
                name="education"
                placeholder="Latest Education"
                value={formData.education}
                onChange={handleChange}
              />
              Education
            </label>

            <label>
              <input
                type="text"
                name="study"
                placeholder="Field of Study"
                value={formData.study}
                onChange={handleChange}
              />
              Study
            </label>
          </div>
          <label>
            <input
              type="text"
              name="location"
              placeholder="City, State, Country"
              value={formData.location}
              onChange={handleChange}
            />
            City Based In
          </label>
          {/* {formData.createAccount === true && loggedIn === false ? (
            <label>
              <input
                type="password"
                name="password"
                value={formData.password}
                placeholder='Password (Atleast 8 Characters)'
                onChange={handlePasswordChange}
                autoComplete="none"
              />
              {error ? <span>{error}</span> : "Password"}
            </label>
          ) : null}
          {loggedIn === false ? (
            <label type="checkbox">
              <input
                defaultChecked='checked'
                type="checkbox"
                name="createAccount"
                value={formData.createAccount}
                onChange={handleChange}
              />
              Create Account?
            </label>
          ) : null} */}
          <Button className={'button'} onClick={changeForm}>
            <h2>Next</h2>
          </Button>
        </form>
        <div className={styles['drop-wrapper']}>
          <Dropzone onFileDrop={handleFileDrop} />
          <label>Resume File*</label>
        </div>
      </div>
    </React.StrictMode>
  );
}

export default UserInfoForm;
