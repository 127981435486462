import React from 'react';

import policyStyles from './Policies.module.css';

import Banner from '../components/Banner';
import Footer from '../components/Footer';

export default function Privacy() {
    return (
        <React.StrictMode>
            <Banner />
            <div className={policyStyles['policy-container']}>
                <h1>Terms of Service</h1>
                <p className={policyStyles['policy-text']}><strong>Last updated:</strong> September 21st, 2024</p>

                <p className={policyStyles['policy-text']}>Welcome to CovrAI ("we," "our," or "us"). By accessing or using our app, you agree to comply with and be bound by the following Terms of Service ("Terms"). Please read these carefully before using our service, which allows users to upload resumes and job descriptions to generate cover letters using AI.</p>

                <h2>1. Use of the App</h2>
                <p className={policyStyles['policy-text']}>Our app allows users to upload resumes and job descriptions for the purpose of generating cover letters. By using the app, you agree that:</p>
                <ul className={policyStyles['policy-list']}>
                    <li>You will use the app only for lawful purposes and in accordance with these Terms.</li>
                    <li>You are responsible for any content you upload.</li>
                    <li>You will not upload any content that is illegal, harmful, or violates the rights of others.</li>
                    <li>You will not attempt to disrupt or interfere with the app’s functionality or security.</li>
                </ul>

                <h2>2. User Accounts</h2>
                <p className={policyStyles['policy-text']}>To use certain features of the app, including generating cover letters, you may be required to create an account. By creating an account, you agree that:</p>
                <ul className={policyStyles['policy-list']}>
                    <li>You are at least 18 years of age or have the legal capacity to enter into a contract.</li>
                    <li>Your information is accurate, complete, and up-to-date.</li>
                    <li>You are responsible for maintaining the confidentiality of your account information and password.</li>
                    <li>You are responsible for any activities or actions under your account.</li>
                </ul>

                <h2>3. Subscription Plans and Payment</h2>
                <p className={policyStyles['policy-text']}>We offer monthly subscription plans that limit the number of cover letter generations per month ("Subscription Plans"). By subscribing to a plan, you agree to the following terms:</p>
                <ul className={policyStyles['policy-list']}>
                    <li><strong>Payment:</strong> You agree to pay the monthly subscription fees, which will be charged to your provided payment method at the start of each subscription period.</li>
                    <li><strong>Auto-Renewal:</strong> Your subscription will automatically renew at the end of each subscription period unless canceled before the renewal date. You authorize us to charge the subscription fee to your payment method upon renewal.</li>
                    <li><strong>Billing Cycle:</strong> The subscription fee will be charged at the beginning of your subscription period and on a recurring monthly basis.</li>
                    <li><strong>Usage Limit:</strong> Each subscription plan includes a limit on the number of cover letter generations you may use each month. Once you reach the limit, additional generations will not be available until the next billing cycle unless you upgrade to a higher plan.</li>
                </ul>

                <h2>4. Cancellation and Refunds</h2>
                <p className={policyStyles['policy-text']}><strong>Cancellation:</strong> You may cancel your subscription at any time through your account settings. Your cancellation will take effect at the end of the current billing cycle, and you will retain access to the app's services until the end of the subscription period.</p>
                <p className={policyStyles['policy-text']}><strong>Refunds:</strong> Subscription fees are non-refundable, except as required by law. No refunds or credits will be provided for unused services or partial months.</p>

                <h2>5. Changes to Subscription Plans</h2>
                <p className={policyStyles['policy-text']}>We reserve the right to change subscription fees or introduce new subscription plans. Any fee changes will be communicated to you before your next billing cycle. If you do not agree with the new fees, you may cancel your subscription before the new fees take effect.</p>

                <h2>6. User Content</h2>
                <p className={policyStyles['policy-text']}>By uploading resumes, job descriptions, or any other content ("User Content") to the app, you grant us a non-exclusive, royalty-free, worldwide license to use, store, and process your content solely for the purpose of providing the service. You retain all ownership rights to your content.</p>

                <h2>7. Intellectual Property</h2>
                <p className={policyStyles['policy-text']}>All content provided in the app, including but not limited to text, images, graphics, and code, is owned by or licensed to CovrAI and is protected by copyright, trademark, and other intellectual property laws.</p>

                <h2>8. Disclaimer of Warranties</h2>
                <p className={policyStyles['policy-text']}>The app is provided on an "as is" and "as available" basis, without any warranties of any kind, either express or implied. We do not warrant that:</p>
                <ul className={policyStyles['policy-list']}>
                    <li>The app will be uninterrupted or error-free.</li>
                    <li>The app will meet your expectations or requirements.</li>
                    <li>Any errors in the app will be corrected.</li>
                </ul>
                <p className={policyStyles['policy-text']}>We disclaim all warranties, including implied warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>

                <h2>9. Limitation of Liability</h2>
                <p className={policyStyles['policy-text']}>To the fullest extent permitted by law, CovrAI shall not be liable for any direct, indirect, incidental, consequential, or special damages arising from your use of the app or inability to use the app, including but not limited to loss of data, loss of revenue, or damages resulting from errors or service interruptions.</p>

                <h2>10. Termination</h2>
                <p className={policyStyles['policy-text']}>We reserve the right to terminate or suspend your account and access to the app at any time, with or without cause or notice, if you violate these Terms or if we believe it is necessary to protect the app, our users, or third parties.</p>

                <h2>11. Changes to the Terms</h2>
                <p className={policyStyles['policy-text']}>We may update these Terms from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated Terms on our website or app. Your continued use of the app following the changes constitutes your acceptance of the new Terms.</p>

                <h2>12. Contact Us</h2>
                <p className={policyStyles['policy-text']}>If you have any questions or concerns about these Terms, please contact us at project-unmei@outlook.com.</p>
            </div>
            <Footer />
        </React.StrictMode>
    )
}