// Home.js
import React from 'react';
import styles from './About.module.css'; // Import CSS for styling
import waterlooImage from '../assets/waterloo.jpg';

import Banner from '../components/Banner';
import Footer from '../components/Footer';

const About = () => {
  return (
    <React.StrictMode>
      <Banner />
      <div className={styles['container']}>
        <section className={styles['section-wrapper']}>
          <div id={styles['title-container']} className={styles['text-section']}>
            <div className={styles['text-overlay']}>
              <div>
                <h1>Your Journey, Our Purpose 🤯</h1>
                <h5>Made with 🩵 From your team @ CovrAI</h5>
              </div>
            </div>
          </div>

          <div id={styles['mission-container']} className={styles['text-section']}>
            <div className={styles['text-overlay']}>
              <div className={styles['textbox-wrapper']}>
                <h1>😶‍🌫️ Building (Y)our Future</h1>
                <h5>Crafted from experience, tailored for you.🤵</h5>
                <div className={styles['details-wrapper']}>
                  <h6>Our team knows firsthand the challenges of navigating the job market, having been through the same process as you, and we're here to make it easier with tailored solutions to help you stand out.</h6>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={styles['team-section']}>
          <div className={styles['team-section-title']}>
            <h1>Meet the CovrAI Team 👋🏼</h1>
            <h5>Our team is made up of exceptional students who are passionate in AI. 🤖</h5>
          </div>
          <div className={styles['team-section-image']}>
            <img src={waterlooImage} alt="UWaterloo" />
          </div>
          <div className={styles['team-members']}>
            <div className={styles['member']}>
              <a href="https://www.linkedin.com/in/thryang/" target="_blank" rel="noopener noreferrer">
                <h5>Tim Yang</h5>
              </a>
              <h6>
                Financial Analysis and Risk Management student at the University of Waterloo with a passion for software development. Tim is the co-founder and software engineer at CovrAI, bringing to the table a unique skillset and a drive for change.
              </h6>
            </div>
            <div className={styles['member']}>
              <a href="https://www.linkedin.com/in/leochai/" target="_blank" rel="noopener noreferrer">
                <h5>Leo Chai</h5>
              </a>
              <h6>
                Leo is a Computer Science & Business Administrative student at the University of Waterloo and Wilfrid Laurier University. Being the co-founder and software engineer at CovrAI Leo brings to the table his extensive experience in software project management.
              </h6>
            </div>
            <div className={styles['member']}>
              <a href="https://www.linkedin.com/in/yang-ji-4590041ab/" target="_blank" rel="noopener noreferrer">
                <h5>Yang Ji</h5>
              </a>
              <h6>
                Yang is a fourth year Computer Science student at the University of Waterloo. With experience in software development across industry leading firms, Yang introduces a robust set of frontend skills in his role as frontend architect at CovrAI.
              </h6>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </React.StrictMode>
  );
};

export default About;

