// Auth.js
import React from 'react';

import Banner from '../components/Banner';
import Footer from '../components/Footer';
import Form from '../components/Auth';


function Home(){
    return (
    <React.StrictMode>
      <Banner />
        <Form />
      <Footer />
    </React.StrictMode>
    )
}


export default Home;