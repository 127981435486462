import React, {useCallback, useState, useRef, useEffect} from 'react'
import { useDropzone } from 'react-dropzone'
import { Document, Page, pdfjs, PDFViewer } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import styles from './Dropzone.module.css';



pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    `pdfjs-dist/build/pdf.worker.min.mjs`,
    import.meta.url,
  ).toString();


const Dropzone = ({ onFileDrop }) => {
    const [pdfFile, setPdfFile] = useState(null);
    const [numPages, setNumPages] = useState(0);


    // Handle file drop
    const onDrop = (acceptedFiles) => {
        onFileDrop(acceptedFiles[0]);
        setPdfFile(acceptedFiles[0]);
        localStorage.setItem('pdfFile', acceptedFiles[0]);
        setNumPages(null);
    };

    const maxSize = 5 * 1024 * 1024; // 5 MB
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone(
        {
            onDrop,
            maxFiles: 1,
            accept: {
                'application/pdf': ['.pdf']
            },
            maxSize
        }
    );

    // Handle PDF load success
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    // Handle file removal
    const removeFile = () => {
        setPdfFile(null);
        setNumPages(null);
    };

    const file = acceptedFiles.map(file => (
        <li key={file.path}>
          {file.path} - {file.size} bytes
        </li>
    ));

    return (
        <React.StrictMode>
            <section className={styles['container']}>
                {!pdfFile ? (
                    <div {...getRootProps()} className={styles["click-drop-wrapper"]}>
                        <input {...getInputProps()} />
                        <h2>Drop Resume to Autofill 😁</h2>
                    </div>
                ) : (
                    <div className={styles["drop-wrapper"]}>
                        <Document className={styles['pdf-wrapper']} file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
                            <Page className={styles['pdf-page-wrapper']} pageNumber={1} renderTextLayer={false} renderAnnotationLayer={false}/>
                        </Document>
                        <button onClick={removeFile}>
                            <h2>Remove File</h2>
                        </button>
                    </div>
                )}
                {/* <div className={styles["file-info-wrapper"]}>
                    <h4>Files</h4>
                    <ul>{numPages ? `Number of Pages: ${numPages}` : 'Loading PDF...'}</ul>
                </div> */}
            </section>
        </React.StrictMode>
    );
};

export default Dropzone;
