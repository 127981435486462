import Cookies from 'universal-cookie';
import { jwtDecode } from "jwt-decode";

export function softLoginCheck(debug) {
  const cookies = new Cookies();
  const token = cookies.get("authorization"); // Fetch the token from storage

  if (token === undefined) {
    console.log('🔴 Soft Missing cookie, redirecting to login.' + debug);
    return false; // No token, not authenticated
  }

  return true;
}

export function getUserInfo(debug) {
  try {
    console.log('🟢 Decoding token.' + debug);
    const cookies = new Cookies();
    const token = cookies.get("authorization"); // Fetch the token from storage

    const info = jwtDecode(token);
    return info;
  } catch (error) {
    console.log('🔴 Error decoding token:', error);
    return null;
  }
}