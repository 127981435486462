import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import styles from './JobInfoForm.module.css';

import GenerateForm from './GenerateForm';
import Loading from './Loading';
import { debug } from '../helpers/Helper'

import { pdfjs } from 'react-pdf';
import { fetchFromServer } from '../helpers/Helper';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  `pdfjs-dist/build/pdf.worker.min.mjs`,
  import.meta.url,
).toString();

const JobInfoForm = ({ changeForm, onFormSubmit, formData, changeFormData, authInfo, userInfo }) => {
  const [loading, setLoading] = useState(false);

  const clearForm = () => {
    const updatedObject = { ...formData };
    // Loop through the form data and clear each field
    ['title', 'company', 'description'].forEach((key) => {
      updatedObject[key] = '';
    });

    // Trigger the update function passed from the parent
    changeFormData(updatedObject);
    console.log('FORM DATA:', updatedObject);
  };

  // Handler to update state based on input changes
  const handleChange = (e) => {
    // Modify the object based on input change
    const { name, type, checked, value } = e.target;
    const inputValue = type === 'checkbox' ? checked : value;
    const updatedObject = { ...formData, [name]: inputValue };

    // Trigger the update function passed from the parent
    changeFormData(updatedObject);
    console.log('FORM DATA:', updatedObject);
  };

  const manualChange = (update) => {
    // Merge the passed update object with formData
    const updatedObject = { ...formData, ...update };
  
    // Trigger the update function passed from the parent
    changeFormData(updatedObject);
  
    console.log('FORM DATA:', updatedObject);
  };
  
  const [images, setImages] = useState([]);

  const handleClick = ( e ) => {
    const imageData = e.currentTarget.getAttribute('data-key');
    debug(imageData);
    manualChange({ desiredTemplate: imageData });
  };

  useEffect(() => {
    // Fetch the image list from the Express API
    const fetchImages = async () => {
      try {
        /*
        const response = await fetch('http://localhost:80/api/templates');
        console.log(response);
        const imageData = await response.json();*/
        const imageData = await fetchFromServer('api/templates','GET');
        debug('🟢 Fetched images:', imageData);
        setImages(imageData);
        if (formData.desiredTemplate === "") {
          manualChange({ desiredTemplate: imageData[0].id })
        };
        
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();
  }, []);

  return (
    <React.StrictMode>
      {loading && <Loading />}
      <div className={styles['content-wrapper']}>
        <div className={styles['field-wrapper']}>
          <form>
            <div className={styles['input-wrapper']}>
              <label>
                <input
                  type="text"
                  name="title"
                  placeholder="Job Title"
                  value={formData.title}
                  onChange={handleChange}
                />
                Job Title*
              </label>
            </div>

            <div className={styles['input-wrapper']}>
              <label>
                <input
                  type="text"
                  name="company"
                  placeholder="Company Name"
                  value={formData.company}
                  onChange={handleChange}
                />
                Company*
              </label>
            </div>

            <div className={styles['input-wrapper']}>
              <label>
                <textarea
                  rows={10}
                  type="text"
                  name="description"
                  placeholder="Paste the job description here 🪄"
                  value={formData.description}
                  onChange={handleChange}
                />
                Job Description*
              </label>
            </div>
            <Button className={'button'} onClick={onFormSubmit}><h2><grad>Generate</grad></h2></Button>
            <div className={styles['button-wrapper']}>
              <Button className={'button'} onClick={changeForm}><h2>Prev</h2></Button>
              <Button className={'button'} onClick={clearForm}><h2><red>Clear</red></h2></Button>
            </div>
          </form>
          <GenerateForm formData={formData} changeFormData={changeFormData} authInfo={authInfo} userInfo={userInfo} />
        </div>
        <div className={styles['slider-wrapper']}>
          <div className={styles['pinned-wrapper']}></div>
          <div className={styles['scroll-wrapper']}>
            { images.map((image, index) => (
              <div key={index} data-key={image.id} className={`${styles['item-wrapper']} ${image.id === formData.desiredTemplate ? styles['selected']: ""}`} onClick={ handleClick }>
                <img src={`https://${process.env.REACT_APP_NODE_IP}${image.src}`} alt={image.alt} className={styles['item-image']} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </React.StrictMode>
  );
}

export default JobInfoForm;
