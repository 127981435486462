import React from 'react';

import policyStyles from './Policies.module.css';

import Banner from '../components/Banner';
import Footer from '../components/Footer';

export default function Privacy() {
    return (
        <React.StrictMode>
            <Banner />
            <div className={policyStyles['policy-container']}>
                <h1>Privacy Policy</h1>
                <p className={policyStyles['policy-text']}><strong>Last updated:</strong> September 21st, 2024</p>

                <p className={policyStyles['policy-text']}>
                    CovrAI ("we," "our," or "us") is committed to protecting your privacy.
                    This Privacy Policy outlines how we collect, use, and safeguard your personal information when you use our service,
                    which allows you to upload resumes and job descriptions to generate cover letters using AI technology.
                    By using our app, you agree to the terms of this policy.
                </p>

                <h2>1. Information We Collect</h2>
                <p className={policyStyles['policy-text']}>We may collect the following types of information when you use our app:</p>
                <ul className={policyStyles['policy-list']}>
                    <li><strong>Personal Information</strong>: Includes your name, email address, and other personal details provided during registration or app usage.</li>
                    <li><strong>Uploaded Documents</strong>: Resumes, job descriptions, or any other files you upload for the purpose of generating a cover letter.</li>
                    <li><strong>Usage Data</strong>: Information about how you interact with the app, including IP address, device information, and browsing behavior.</li>
                </ul>

                <h2>2. How We Use Your Information</h2>
                <p className={policyStyles['policy-text']}>We use your information for the following purposes:</p>
                <ul className={policyStyles['policy-list']}>
                    <li><strong>Service Delivery</strong>: To generate cover letters based on the resumes and job descriptions you upload.</li>
                    <li><strong>Communication</strong>: To send you updates, notifications, or respond to inquiries.</li>
                    <li><strong>App Improvement</strong>: To improve the functionality of our app by analyzing usage patterns and troubleshooting issues.</li>
                </ul>

                <h2>3. Data Storage and Security</h2>
                <p className={policyStyles['policy-text']}>
                    We are committed to securing your data and use reasonable technical and organizational measures to protect it from unauthorized access,
                    alteration, disclosure, or destruction.
                </p>
                <ul className={policyStyles['policy-list']}>
                    <li><strong>Data Storage</strong>: Uploaded documents are stored securely and are not shared with third parties, except as necessary to provide the service.</li>
                    <li><strong>Data Retention</strong>: We retain your personal information and uploaded documents only for as long as necessary to fulfill the purposes for which they were collected or as required by law.</li>
                </ul>
                <h2>4. Sharing of Information</h2>
                <p className={policyStyles['policy-text']}>
                    We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except:
                </p>
                <ul className={policyStyles['policy-list']}>
                    <li>When required by law or in response to legal processes.</li>
                    <li>To trusted service providers who assist us in operating our app, provided they agree to keep your information confidential.</li>
                </ul>

                <h2>5. Your Rights</h2>
                <p className={policyStyles['policy-text']}>You have the right to:</p>
                <ul className={policyStyles['policy-list']}>
                    <li><strong>Access</strong>: Request access to the personal information we hold about you.</li>
                    <li><strong>Correction</strong>: Request corrections to any inaccurate or incomplete information.</li>
                    <li><strong>Deletion</strong>: Request the deletion of your personal data, subject to legal obligations.</li>
                    <li><strong>Withdrawal of Consent</strong>: If you have given consent for data collection, you can withdraw it at any time.</li>
                </ul>

                <h2>6. Cookies and Tracking Technologies</h2>
                <p className={policyStyles['policy-text']}>
                    Our app may use cookies and similar tracking technologies to enhance your experience. You can control cookie preferences through your browser settings.
                </p>

                <h2>7. Changes to This Policy</h2>
                <p className={policyStyles['policy-text']}>
                    We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated policy on our website.
                </p>

                <h2>8. Contact Us</h2>
                <p className={policyStyles['policy-text']}>If you have any questions or concerns about this Privacy Policy, please contact us at project-unmei@outlook.com.</p>
            </div>
            <Footer />
        </React.StrictMode>
    )
}