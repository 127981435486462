import Cookies from 'universal-cookie';
export var debug

if (process.env.REACT_APP_MODE === "DEVELOPMENT") 
    debug = console.log.bind(window.console);
else 
    debug = function(){};

const protocol = process.env.REACT_APP_MODE === "DEVELOPMENT" ? "http" : "https";

export async function fetchFromServer(
  endpoint, 
  method, 
  data = undefined,
  callback = ()=>{},
  token = undefined, 
  error = 'Response not ok') {
  
  if (!token) {
    const cookies = new Cookies();
    token = cookies.get("authorization"); // Fetch the token from storage
    if (token === undefined) 
      console.log('🔴 Missing cookie, redirecting to login.');
  }
  return new Promise( async (resolve, reject) => {
    debug(`fetching from ${endpoint} with ${method} and cookie ${token} for ${data}`);
    fetch(`${protocol}://${process.env.REACT_APP_NODE_IP}/${endpoint}`, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: data
    }).then((async function (res) {
      let resjson = {};
      const contentType = res.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        resjson = await res.json();
      }
      resjson.ok = res.ok;
      resjson.status = res.status;

      if (res.ok) {
        callback(resjson);
      } else {
        debug(error);
      }

      resolve(resjson);
    })).catch(function (err) {
      debug(err);
      reject();
    });
  });
}

export function dateStr() {
  const date = new Date();
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();

  // Function to get ordinal suffix for day
  const getOrdinalSuffix = (day) => {
      if (day > 3 && day < 21) return 'th'; // Covers 11th, 12th, 13th
      switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
      }
  };

  return `${month} ${day}${getOrdinalSuffix(day)}, ${year}`;
}
