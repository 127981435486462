// components/Header.js
import React from 'react';
import styles from './BannerSpacer.module.css';
import { Link } from 'react-router-dom';

function Banner() {
  return (
    <div className={styles['banner-wrapper']}>
    </div>
  );
}

export default Banner;
